/**
 * Схема для начальной страницы работы со сверками
 * @property {Array} actionList - список кнопок с действиями
 */
const SchemePageReconciliationStart = {
    actionList: [
        {
            text: 'Загрузить сверки',
            view: 'secondary',
            form: 'oval',
            emitName: 'onDownload'
        },
        {
            text: 'Создать шаблон',
            form: 'oval',
            emitName: 'onCreate'
        },
        {
            text: 'История импорта',
            form: 'oval',
            position: 'right',
            emitName: 'onHistory'
        }
    ]
};

export default SchemePageReconciliationStart;
