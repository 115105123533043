<template>
    <layout-reconciliation-start
        :actionList="actionList"
        modePage="start" 
    />
</template>

<script>
/** Начальная страница работы со сверками */
import LayoutReconciliationStart from '@/layouts/LayoutReconciliationStart';
import SchemePageReconciliationStart from '@/schemes/SchemePageReconciliationStart';
const {actionList} = SchemePageReconciliationStart;
export default {
    name: 'PageReconciliationStart',
    components: {LayoutReconciliationStart},
    /**
     * Данные комопнента
     * @property {Array} actionList - список кнопок с действиями
     */
    data: () => ({
        actionList: actionList,
    }),
}
</script>
